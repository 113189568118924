/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

const ADN_REPORT_COMPONENTS = (function() {
  const selfServiceFields = {
    rendered: true,
    visibles: true,
    visibility: true,
    viewables: true,
    viewability: true,
    clicks: true,
    ctr: true,
    uniqueUsers: true
  };

  const selfServiceTotalsFields: any = _.cloneDeep(selfServiceFields);
  selfServiceTotalsFields.billedAmount = false;

  const topCreativeFields = {
    externalId: false,
    impressions: true,
    measurableImpressions: false,
    rendered: true,
    visibles: true,
    visibility: true,
    viewables: true,
    viewability: true,
    clicks: true,
    ctr: true,
    uniqueUsers: true,
    earnings: true,
    fee: false,
    techFee: false,
    eCpm: true,
    customEvents: false,
    customEventsAverages: false
  };

  const defaultFields = _.cloneDeep(topCreativeFields);
  delete defaultFields.externalId;

  const orderBreakdownFields: any = _.cloneDeep(defaultFields);
  orderBreakdownFields.order = true;
  orderBreakdownFields.advertiser = true;
  orderBreakdownFields.vatId = false;
  orderBreakdownFields.creationDate = false;
  orderBreakdownFields.startDate = false;
  orderBreakdownFields.endDate = false;
  orderBreakdownFields.billedAmount = false;

  const lineItemTotalsFields: any = _.cloneDeep(defaultFields);
  lineItemTotalsFields.billedAmount = false;

  const networkFields = {
    impressions: true,
    measurableImpressions: false,
    rendered: true,
    clicks: true,
    visibles: true,
    visibility: true,
    viewables: true,
    viewability: true,
    ctr: true,
    uniqueUsers: true,
    earnings: true,
    eCpm: true,
    customEvents: false,
    customEventsAverages: false
  };

  const creativeBreakdownFields = {
    externalId: false,
    impressions: true,
    measurableImpressions: false,
    rendered: true,
    clicks: true,
    visibles: true,
    visibility: true,
    viewables: true,
    viewability: true,
    ctr: true,
    uniqueUsers: true,
    earnings: true,
    fee: false,
    techFee: false,
    eCpm: true,
    lineItem: true,
    order: true,
    advertiser: true,
    customEvents: false,
    customEventsAverages: false
  };

  const targetingFields = {
    impressions: true,
    measurableImpressions: false,
    rendered: true,
    visibles: true,
    visibility: true,
    viewables: true,
    viewability: true,
    clicks: true,
    ctr: true
  };

  const pubFields = {
    requests: true,
    positiveRequests: true,
    matchRate: true,
    impressions: true,
    measurableImpressions: false,
    rendered: true,
    visibles: true,
    visibility: true,
    viewables: true,
    viewability: true,
    clicks: true,
    ctr: true,
    uniqueUsers: true,
    earnings: true,
    revenueShare: false,
    eCpm: true,
    customEvents: false,
    customEventsAverages: false
  };

  const getParameters = function(heading, topN?, fields?) {
    const params: any = {fields: (fields || defaultFields)};
    if (heading) {
      params.heading = heading;
    }
    if (topN) {
      params.limit = topN;
    }
    return params;
  };

  const addDateTimeFormat = function(dataObj, prop?) {
    dataObj[prop || 'dateFormat'] = "";
    return dataObj;
  };

  const addChartParameters = function(dataObj) {
    dataObj.sharedYAxis = false;
    dataObj.height = 500;
    return dataObj;
  };

  const advTranslationOverrides = {earnings: 'cost'};
  return {
    lineitemdescriptionreportcomponentcreativeset: {
      id: 'lineitemdescriptionreportcomponentcreativeset',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'CreativeSet Description', hideCreativesWithoutStats: true},
      translationOverrides: advTranslationOverrides
    },
    lineitemtotalsreportcomponentcreativeset: {
      id: 'lineitemtotalsreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("CreativeSet Totals", null, lineItemTotalsFields),
      translationOverrides: advTranslationOverrides
    },
    textreportcomponentcreativeset: {
      id: 'textreportcomponentcreativeset',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    },
    dailybreakdownreportcomponentcreativeset: {
      id: 'dailybreakdownreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown")),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownchartreportcomponentcreativeset: {
      id: 'dailybreakdownchartreportcomponentcreativeset',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart")),
      translationOverrides: advTranslationOverrides
    },
    topcreativesreportcomponentcreativeset: {
      id: 'topcreativesreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creatives", null, topCreativeFields),
      translationOverrides: advTranslationOverrides
    },
    creativebreakdownreportcomponentcreativeset: {
      id: 'creativebreakdownreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creative Breakdown", null, creativeBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    topadunitsreportcomponentcreativeset: {
      id: 'topadunitsreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Ad Units", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    toplocationsreportcomponentcreativeset: {
      id: 'toplocationsreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Locations", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topmobilebrandsreportcomponentcreativeset: {
      id: 'topmobilebrandsreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Android Brands", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topoperatingsystemsreportcomponentcreativeset: {
      id: 'topoperatingsystemsreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Operating Systems", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topkeyvaluesreportcomponentcreativeset: {
      id: 'topkeyvaluesreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Key-Values", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topcategoriesreportcomponentcreativeset: {
      id: 'topcategoriesreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Categories", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topdevicetypesreportcomponentcreativeset: {
      id: 'topdevicetypesreportcomponentcreativeset',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Device Types", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    lineitemdescriptionreportcomponentlineitem: {
      id: 'lineitemdescriptionreportcomponentlineitem',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Line Item Description', hideCreativesWithoutStats: true},
      translationOverrides: advTranslationOverrides
    },
    lineitemtotalsreportcomponentlineitem: {
      id: 'lineitemtotalsreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Line Item Totals", null, lineItemTotalsFields),
      translationOverrides: advTranslationOverrides
    },
    campaigndescriptionreportcomponentselfservice: {
      id: 'campaigndescriptionreportcomponentselfservice',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Campaign Description'},
      translationOverrides: advTranslationOverrides
    },
    campaigntotalsreportcomponentselfservice: {
      id: 'campaigntotalsreportcomponentselfservice',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Campaign Totals", null, selfServiceTotalsFields),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownreportcomponentselfservice: {
      id: 'dailybreakdownreportcomponentselfservice',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Daily Breakdown", null, selfServiceFields),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownchartreportcomponentselfservice: {
      id: 'dailybreakdownchartreportcomponentselfservice',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart", null, selfServiceFields)),
      translationOverrides: advTranslationOverrides
    },
    textreportcomponentlineitem: {
      id: 'textreportcomponentlineitem',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    },
    dailybreakdownreportcomponentlineitem: {
      id: 'dailybreakdownreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown")),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownchartreportcomponentlineitem: {
      id: 'dailybreakdownchartreportcomponentlineitem',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart")),
      translationOverrides: advTranslationOverrides
    },
    topcreativesreportcomponentlineitem: {
      id: 'topcreativesreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creatives", null, topCreativeFields),
      translationOverrides: advTranslationOverrides
    },
    creativedailybreakdownreportcomponentlineitem: {
      id: 'creativedailybreakdownreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Daily Creative Breakdown", null, topCreativeFields),
      translationOverrides: advTranslationOverrides
    },
    creativebreakdownreportcomponentlineitem: {
      id: 'creativebreakdownreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creative Breakdown", null, creativeBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    topadunitsreportcomponentlineitem: {
      id: 'topadunitsreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Ad Units", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    toplocationsreportcomponentlineitem: {
      id: 'toplocationsreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Locations", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topmobilebrandsreportcomponentlineitem: {
      id: 'topmobilebrandsreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Android Brands", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topoperatingsystemsreportcomponentlineitem: {
      id: 'topoperatingsystemsreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Operating Systems", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topkeyvaluesreportcomponentlineitem: {
      id: 'topkeyvaluesreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Key-Values", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topcategoriesreportcomponentlineitem: {
      id: 'topcategoriesreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Categories", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    topdevicetypesreportcomponentlineitem: {
      id: 'topdevicetypesreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Device Types", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    targetingbreakdownreportcomponentlineitem: {
      id: 'targetingbreakdownreportcomponentlineitem',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Targeting Breakdown", null, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownreportcomponentlineitemlist: {
      id: 'dailybreakdownreportcomponentlineitemlist',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown")),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownreportcomponentadvertiser: {
      id: 'dailybreakdownreportcomponentadvertiser',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown")),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownchartreportcomponentlineitemlist: {
      id: 'dailybreakdownchartreportcomponentlineitemlist',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart")),
      translationOverrides: advTranslationOverrides
    },
    textreportcomponentlineitemlist: {
      id: 'textreportcomponentlineitemlist',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""},
      translationOverrides: advTranslationOverrides
    },
    toplocationsreportcomponentlineitemlist: {
      id: 'toplocationsreportcomponentlineitemlist',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Top %n% Locations", 20, targetingFields),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownchartreportcomponentadvertiser: {
      id: 'dailybreakdownchartreportcomponentadvertiser',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart")),
      translationOverrides: advTranslationOverrides
    },
    lineitembreakdownreportcomponentlineitemlist: {
      id: 'lineitembreakdownreportcomponentlineitemlist',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Line Item Breakdown", null, orderBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    lineitembreakdownreportcomponentadvertiser: {
      id: 'lineitembreakdownreportcomponentadvertiser',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Line Item Breakdown", null, orderBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    orderbreakdownreportcomponentadvertiser: {
      id: 'orderbreakdownreportcomponentadvertiser',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Order Breakdown", null, orderBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    creativebreakdownreportcomponentlineitemlist: {
      id: 'creativebreakdownreportcomponentlineitemlist',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creative Breakdown", null, creativeBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    creativebreakdownreportcomponentadvertiser: {
      id: 'creativebreakdownreportcomponentadvertiser',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creative Breakdown", null, creativeBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    orderdescriptionreportcomponentlineitemlist: {
      id: 'orderdescriptionreportcomponentlineitemlist',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Order Description'},
      translationOverrides: advTranslationOverrides
    },
    advertiserdescriptionreportcomponentadvertiser: {
      id: 'advertiserdescriptionreportcomponentadvertiser',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Advertiser Description'},
      translationOverrides: advTranslationOverrides
    },
    textreportcomponentadvertiser: {
      id: 'textreportcomponentadvertiser',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""},
      translationOverrides: advTranslationOverrides
    },
    networkbreakdownreportcomponentnetwork: {
      id: 'networkbreakdownreportcomponentnetwork',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(addDateTimeFormat(getParameters("Network Breakdown", null, networkFields), 'dateTimeFormatLineItem'))
    },
    networktotalsreportcomponentnetwork: {
      id: 'networktotalsreportcomponentnetwork',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Network Totals", null, networkFields)
    },
    networkdescriptionreportcomponentnetwork: {
      id: 'networkdescriptionreportcomponentnetwork',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Network Description'}
    },
    textreportcomponentnetwork: {
      id: 'textreportcomponentnetwork',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    },
    adunitdescriptionreportcomponentadunit: {
      id: 'adunitdescriptionreportcomponentadunit',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Ad Unit Description'}
    },
    publishingtotalsreportcomponentadunit: {
      id: 'publishingtotalsreportcomponentadunit',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Ad Unit Totals", null, pubFields)
    },
    targetingbreakdownreportcomponentadunit: {
      id: 'targetingbreakdownreportcomponentadunit',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Targeting Breakdown", null, targetingFields)
    },
    dailybreakdownreportcomponentadunit: {
      id: 'dailybreakdownreportcomponentadunit',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown", null, pubFields))
    },
    textreportcomponentadunit: {
      id: 'textreportcomponentadunit',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    },
    dailybreakdownchartreportcomponentadunit: {
      id: 'dailybreakdownchartreportcomponentadunit',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart", null, pubFields))
    },
    ordertotalsreportcomponentlineitemlist: {
      id: 'ordertotalsreportcomponentlineitemlist',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Order Totals", null, lineItemTotalsFields),
      translationOverrides: advTranslationOverrides
    },
    advertisertotalsreportcomponentadvertiser: {
      id: 'advertisertotalsreportcomponentadvertiser',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Advertiser Totals", null, lineItemTotalsFields),
      translationOverrides: advTranslationOverrides
    },
    advertisingtotalsreportcomponentsalesperson: {
      id: 'advertisingtotalsreportcomponentsalesperson',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Advertising Totals", null, lineItemTotalsFields),
      translationOverrides: advTranslationOverrides
    },
    salespersondescriptionreportcomponentsalesperson: {
      id: 'salespersondescriptionreportcomponentsalesperson',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: {heading: 'Salesperson Description'},
      translationOverrides: advTranslationOverrides
    },
    textreportcomponentsalesperon: {
      id: 'textreportcomponentsalesperson',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""},
      translationOverrides: advTranslationOverrides
    },
    lineitembreakdownreportcomponentsalesperson: {
      id: 'lineitembreakdownreportcomponentsalesperson',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Line Item Breakdown", null, orderBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    creativebreakdownreportcomponentsalesperson: {
      id: 'creativebreakdownreportcomponentsalesperson',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Creative Breakdown", null, creativeBreakdownFields),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownreportcomponentsalesperson: {
      id: 'dailybreakdownreportcomponentsalesperson',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown")),
      translationOverrides: advTranslationOverrides
    },
    dailybreakdownchartreportcomponentsalesperson: {
      id: 'dailybreakdownchartreportcomponentsalesperson',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart")),
      translationOverrides: advTranslationOverrides
    },
    adunitlistdescriptionreportcomponentsite: {
      id: 'adunitlistdescriptionreportcomponentsite',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: getParameters("Description", null, pubFields)
    },
    adunitbreakdownreportcomponentsite: {
      id: 'adunitbreakdownreportcomponentsite',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Ad Unit Breakdown", null, pubFields)
    },
    sitebreakdownreportcomponentearningsaccount: {
      id: 'sitebreakdownreportcomponentearningsaccount',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Site Breakdown", null, pubFields)
    },
    textreportcomponentsite: {
      id: 'textreportcomponentsite',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    },
    dailybreakdownreportcomponentsite: {
      id: 'dailybreakdownreportcomponentsite',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown", null, pubFields))
    },
    dailybreakdownchartreportcomponentsite: {
      id: 'dailybreakdownchartreportcomponentsite',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart", null, pubFields))
    },
    adunitlistdescriptionreportcomponentsitegroup: {
      id: 'adunitlistdescriptionreportcomponentsitegroup',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: getParameters("Description", null, pubFields)
    },
    adunitbreakdownreportcomponentsitegroup: {
      id: 'adunitbreakdownreportcomponentsitegroup',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Ad Unit Breakdown", null, pubFields)
    },
    dailybreakdownreportcomponentsitegroup: {
      id: 'dailybreakdownreportcomponentsitegroup',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown", null, pubFields))
    },
    textreportcomponentsitegroup: {
      id: 'textreportcomponentsitegroup',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    },
    dailybreakdownchartreportcomponentsitegroup: {
      id: 'dailybreakdownchartreportcomponentsitegroup',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart", null, pubFields))
    },
    publishingtotalsreportcomponentsite: {
      id: 'publishingtotalsreportcomponentsite',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Site Totals", null, pubFields)
    },
    publishingtotalsreportcomponentearningsaccount: {
      id: 'publishingtotalsreportcomponentearningsaccount',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Earnings Account Totals", null, pubFields)
    },
    publishingtotalsreportcomponentsitegroup: {
      id: 'publishingtotalsreportcomponentsitegroup',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-summary.jpg',
      parameters: getParameters("Site Group Totals", null, pubFields)
    },
    adunitlistdescriptionreportcomponentearningsaccount: {
      id: 'adunitlistdescriptionreportcomponentearningsaccount',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-description.jpg',
      parameters: getParameters("Description", null, pubFields)
    },
    adunitbreakdownreportcomponentearningsaccount: {
      id: 'adunitbreakdownreportcomponentearningsaccount',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: getParameters("Ad Unit Breakdown", null, pubFields)
    },
    dailybreakdownreportcomponentearningsaccount: {
      id: 'dailybreakdownreportcomponentearningsaccount',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown.jpg',
      parameters: addDateTimeFormat(getParameters("Daily Breakdown", null, pubFields))
    },
    dailybreakdownchartreportcomponentearningsaccount: {
      id: 'dailybreakdownchartreportcomponentearningsaccount',
      csv: true,
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-daily-breakdown-chart.jpg',
      parameters: addChartParameters(getParameters("Daily Breakdown Chart", null, pubFields))
    },
    textreportcomponentearningsaccount: {
      id: 'textreportcomponentearningsaccount',
      thumbnail: 'https://regalia.adnuntius.com/ui/ui-text-report.png',
      parameters: {text: ""}
    }
  };
})();

const ADN_SCHEDULE_PERIODS = {
  'RECURRENCE_PERIOD': {
    id: 'RECURRENCE_PERIOD'
  },
  'FLIGHT': {
    id: 'FLIGHT'
  },
  'DAILY': {
    id: 'DAILY'
  },
  'WEEKLY': {
    id: 'WEEKLY'
  },
  'MONTHLY': {
    id: 'MONTHLY'
  },
  'CURRENT_MONTH': {
    id: 'CURRENT_MONTH'
  }
};

const ADN_REPORT_TYPES = (function() {
  const lineItemPeriods = [ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD, ADN_SCHEDULE_PERIODS.FLIGHT, ADN_SCHEDULE_PERIODS.DAILY, ADN_SCHEDULE_PERIODS.WEEKLY, ADN_SCHEDULE_PERIODS.MONTHLY, ADN_SCHEDULE_PERIODS.CURRENT_MONTH];
  const notLineItemPeriods = [ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD, ADN_SCHEDULE_PERIODS.DAILY, ADN_SCHEDULE_PERIODS.WEEKLY, ADN_SCHEDULE_PERIODS.MONTHLY, ADN_SCHEDULE_PERIODS.CURRENT_MONTH];

  return {
    LINE_ITEM: {
      id: 'LINE_ITEM',
      idKey: 'lineItemId',
      objKey: 'lineItem',
      objType: 'LineItem',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: lineItemPeriods,
      specifyDates: false,
      components: [
        ADN_REPORT_COMPONENTS.lineitemdescriptionreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.lineitemtotalsreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.textreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.targetingbreakdownreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topcreativesreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.creativedailybreakdownreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topadunitsreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.toplocationsreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topmobilebrandsreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topoperatingsystemsreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topkeyvaluesreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topcategoriesreportcomponentlineitem,
        ADN_REPORT_COMPONENTS.topdevicetypesreportcomponentlineitem
      ]
    },
    LINE_ITEM_LIST: {
      id: 'LINE_ITEM_LIST',
      idKey: 'orderId',
      objKey: 'order',
      objType: 'Order',
      idsKey: 'lineItemIds',
      objsKey: 'lineItem',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: lineItemPeriods,
      specifyDates: false,
      components: [
        ADN_REPORT_COMPONENTS.orderdescriptionreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.ordertotalsreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.lineitembreakdownreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.creativebreakdownreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.textreportcomponentlineitemlist,
        ADN_REPORT_COMPONENTS.toplocationsreportcomponentlineitemlist
      ]
    },
    ADVERTISER: {
      id: 'ADVERTISER',
      idKey: 'advertiserId',
      objKey: 'advertiser',
      objType: 'Advertiser',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: lineItemPeriods,
      perms: 'MANAGE_BUSINESS',
      specifyDates: false,
      components: [
        ADN_REPORT_COMPONENTS.advertiserdescriptionreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.advertisertotalsreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.orderbreakdownreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.lineitembreakdownreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.creativebreakdownreportcomponentadvertiser,
        ADN_REPORT_COMPONENTS.textreportcomponentadvertiser
      ],
      allActiveFlag: 'ALL_ACTIVE'
    },
    CREATIVE_SET: {
      id: 'CREATIVE_SET',
      idKey: 'creativeSetId',
      objKey: 'creativeSet',
      objType: 'CreativeSet',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: lineItemPeriods,
      specifyDates: false,
      components: [
        ADN_REPORT_COMPONENTS.lineitemdescriptionreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.lineitemtotalsreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.textreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topcreativesreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topadunitsreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.toplocationsreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topmobilebrandsreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topoperatingsystemsreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topkeyvaluesreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topcategoriesreportcomponentcreativeset,
        ADN_REPORT_COMPONENTS.topdevicetypesreportcomponentcreativeset
      ]
    },
    NETWORK: {
      id: 'NETWORK',
      idKey: 'networkId',
      objKey: 'network',
      objType: 'Network',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: notLineItemPeriods,
      specifyDates: true,
      perms: 'MANAGE_SYSTEM',
      components: [
        ADN_REPORT_COMPONENTS.networkdescriptionreportcomponentnetwork,
        ADN_REPORT_COMPONENTS.networktotalsreportcomponentnetwork,
        ADN_REPORT_COMPONENTS.networkbreakdownreportcomponentnetwork,
        ADN_REPORT_COMPONENTS.textreportcomponentnetwork
      ]
    },
    SITE: {
      id: 'SITE',
      idKey: 'siteId',
      objKey: 'site',
      objType: 'Site',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: notLineItemPeriods,
      specifyDates: true,
      components: [
        ADN_REPORT_COMPONENTS.adunitlistdescriptionreportcomponentsite,
        ADN_REPORT_COMPONENTS.publishingtotalsreportcomponentsite,
        ADN_REPORT_COMPONENTS.adunitbreakdownreportcomponentsite,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentsite,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentsite,
        ADN_REPORT_COMPONENTS.textreportcomponentsite
      ]
    },
    AD_UNIT: {
      id: 'AD_UNIT',
      idKey: 'adUnitId',
      objKey: 'adUnit',
      objType: 'AdUnit',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: notLineItemPeriods,
      specifyDates: true,
      components: [
        ADN_REPORT_COMPONENTS.adunitdescriptionreportcomponentadunit,
        ADN_REPORT_COMPONENTS.publishingtotalsreportcomponentadunit,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentadunit,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentadunit,
        ADN_REPORT_COMPONENTS.targetingbreakdownreportcomponentadunit,
        ADN_REPORT_COMPONENTS.textreportcomponentadunit
      ]
    },
    SITE_GROUP: {
      id: 'SITE_GROUP',
      idKey: 'siteGroupId',
      objKey: 'siteGroup',
      objType: 'SiteGroup',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: notLineItemPeriods,
      specifyDates: true,
      components: [
        ADN_REPORT_COMPONENTS.adunitlistdescriptionreportcomponentsitegroup,
        ADN_REPORT_COMPONENTS.publishingtotalsreportcomponentsitegroup,
        ADN_REPORT_COMPONENTS.adunitbreakdownreportcomponentsitegroup,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentsitegroup,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentsitegroup,
        ADN_REPORT_COMPONENTS.textreportcomponentsitegroup
      ]
    },
    EARNINGS_ACCOUNT: {
      id: 'EARNINGS_ACCOUNT',
      idKey: 'earningsAccountId',
      objKey: 'earningsAccount',
      objType: 'EarningsAccount',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: notLineItemPeriods,
      perms: 'MANAGE_BUSINESS',
      specifyDates: true,
      components: [
        ADN_REPORT_COMPONENTS.adunitlistdescriptionreportcomponentearningsaccount,
        ADN_REPORT_COMPONENTS.publishingtotalsreportcomponentearningsaccount,
        ADN_REPORT_COMPONENTS.sitebreakdownreportcomponentearningsaccount,
        ADN_REPORT_COMPONENTS.adunitbreakdownreportcomponentearningsaccount,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentearningsaccount,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentearningsaccount,
        ADN_REPORT_COMPONENTS.textreportcomponentearningsaccount
      ]
    },
    SALESPERSON: {
      id: 'SALESPERSON',
      idKey: 'salesPersonId',
      objKey: 'salespersonUser',
      objType: 'SalespersonUser',
      defaultPeriod: ADN_SCHEDULE_PERIODS.RECURRENCE_PERIOD,
      periods: notLineItemPeriods,
      perms: ['MANAGE_ADVERTISING', 'RUN_REPORTS'],
      specifyDates: false,
      components: [
        ADN_REPORT_COMPONENTS.salespersondescriptionreportcomponentsalesperson,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentsalesperson,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentsalesperson,
        ADN_REPORT_COMPONENTS.lineitembreakdownreportcomponentsalesperson,
        ADN_REPORT_COMPONENTS.creativebreakdownreportcomponentsalesperson,
        ADN_REPORT_COMPONENTS.advertisingtotalsreportcomponentsalesperson,
        ADN_REPORT_COMPONENTS.textreportcomponentsalesperon
      ]
    },
    SELF_SERVICE: {
      id: 'SELF_SERVICE',
      idKey: 'lineItemId',
      objKey: 'lineItem',
      objType: 'LineItem',
      specifyDates: false,
      components: [
        ADN_REPORT_COMPONENTS.campaigndescriptionreportcomponentselfservice,
        ADN_REPORT_COMPONENTS.campaigntotalsreportcomponentselfservice,
        ADN_REPORT_COMPONENTS.dailybreakdownchartreportcomponentselfservice,
        ADN_REPORT_COMPONENTS.dailybreakdownreportcomponentselfservice
      ]
    }
  };
})();

export {ADN_REPORT_COMPONENTS, ADN_REPORT_TYPES, ADN_SCHEDULE_PERIODS};